import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Dialog, TableRow, TableCell, Tooltip, IconButton,Checkbox } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "../components/ConfirmDialog";
import { fillSvEditFormApi } from "../apiServices";
import SvTaskForm from "./SvTaskForm";
import moment from "moment";
import { regexConstants } from "../../../../constants/regEx";
import {dayMonthDateFormat } from "../../../../constants"
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { permissionCheckFnforCamo, checkApiStatus ,Comma_format} from "../../../../utils";
import { globalPutService, globalDeleteService ,globalGetService} from "../../../../utils/globalApiServices";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";
import Switch from '@material-ui/core/Switch';

const SvList = ({ item, index, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, downloadAllApi, uuid, last_used_folder_uuid, getResponseBack, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name,assetsDetail,bulkOperation, toggleBulkOps,basicDetail,techSpecDetail }) => {
    const [open, setOpen] = useState(false);
    const [openEditForm, setEditForm] = useState(false);
    const [editFormId, setEditFormId] = useState(null);
    const [editFormData, setEditFormData] = useState(null);
    const [formTitle, setFormTitle] = useState("");
    const [attachments, setAttachments] = useState(null);
    const [showFullDescription, setShowFullDescription] = useState({});
    const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
    const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
        useState(null);
    const [moduleId, setmoduleId] = useState(null);
    const [actionType, setActionType] = useState("");
    const [svToggel, setSvTogle] = useState(item?.sv_reset_tslsv_cslsv)

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const closeAddForm = () => {
        setEditForm(false);
    };

    const handleEditRow = () => {
        fillSvEditFormApi(item, props).then((response) => {
            setEditFormData(response.data.data);
            setEditFormId(response.data.data.id);
            setAttachments(response.data.data.attachments);
            setFormTitle("Update Shop Visit");
            setEditForm(true);
            setActionType('edit')
        });
    };

    const downAllAttach = () => {
        fillSvEditFormApi(item, props).then((response) => {
            setmoduleId(response.data.data.id);
        });
        getAttachements()
    };
    const getAttachements = () => {
        const matchingItem = assetsDetail?.list?.find(items => items?.id === item.id);
        if (!matchingItem) return [];
        const { checklist, attachments } = matchingItem || {};
        if (checklist && attachments || checklist || attachments) {
            const { files, folders } = checklist || {}; // Add null check here
            if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
                getAttachments([...attachments, ...files, ...folders])
            } else if (files?.length > 0 && folders?.length > 0) {
                getAttachments([...folders, ...files])
            } else if (attachments?.length > 0 && files?.length > 0) {
                getAttachments([...attachments, ...files])
            } else if (attachments?.length > 0 && folders?.length > 0) {
                getAttachments([...attachments, ...folders])
            } else if (attachments?.length > 0) {
                getAttachments([...attachments])
            } else if (folders?.length > 0) {
                getAttachments([...folders])
            } else if (files?.length > 0) {
                getAttachments([...files])
            }
        }
        return [];

    };

    const openAttachment = (file) => {
        var decodedURL = decodeURIComponent(file);
        window.open(decodedURL, "_blank");
    };

    const formatDate = (date) => {
        return moment(date).format(dayMonthDateFormat);
    };

    const toggleDescription = (id) => {
        setShowFullDescription({
            ...showFullDescription,
            [id]: !showFullDescription[id],
        });
    };

    const getAttachments = (attachments) => {
        setAllAttachmentsforDialogue(attachments);
        setAttachmentsDialogue(true);
    };

    const handleCloseAttachmentDialogue = () => {
        setAttachmentsDialogue(false);
    };
    
    const resetTslvCslv = (flag) => {
        globalPutService(`camo/shop/reset_tslsv_cslsv/${item?.id}/?reset=${!flag}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setSvTogle(response?.data?.data?.sv_reset_tslsv_cslsv)
                    props.enqueueSnackbar(response.data.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                    window.location.reload()
                } else {
                    props.enqueueSnackbar(response.data.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                }
            })
    }
    const removeAttachment = (item, checklist, fileId, uuid) => {
        const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
        const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
        if (uuid) {
            let payload = {};
            payload.delete = true;
            payload.folder_ids = checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
            payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
            globalPutService(`camo/shop/${item?.id}/document-checklists/`, payload)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        fillSvEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
                            const matchingItem = response.data.data;
                            if (!matchingItem) return [];
                            const { checklist, attachments } = matchingItem || {};
                            if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

                            return [];
                        });
                    }
                })
        } else {
            globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
                if (response.status === 500) {
                    props.enqueueSnackbar("Something went wrong.", {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                } else {
                    if (response) {
                        fillSvEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

                            return [];
                        });
                    }
                }
            });
        }
    };
    let timezone = moment().format()
    const timestamp = Math.floor(Date.now() / 1000);
    const svurl = `audit/camo/shopvisit/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`

    function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};

        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;

        if (attachmentCount && fileCount && folderCount) {
            return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
            return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
            return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
            return attachmentCount + fileCount;
        } else {
            return folderCount || fileCount || attachmentCount || '--';
        }
    }
    const attachmentCount = getAttachmentCount(item);

    const selectedItem = bulkOperation.ids.find(data => data.id === item?.id);
    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
                style={{ cursor: "pointer" }}
                className={
                    item.remainingValues_D < 0 || item.remainingValues_D == null
                        ? "red-row"
                        : "none"
                }
            >
             <TableCell width="30">
                    <Checkbox
                        name={item.id}
                        color="primary"
                        size="small"
                        onChange={(e) => toggleBulkOps(e.target.checked)}
                        checked={selectedItem ? true : false}
                    />
                </TableCell>
                <TableCell  className="sv-action">
                    {permissionCheckFnforCamo({
                        primaryKey: "occm_component",
                        keyIndex: "U",
                    }) ? (
                        <Tooltip title="Edit">
                            <IconButton
                                className="edit-icon"
                                onClick={(e) => {
                                    handleEditRow(e);
                                }}
                            >
                                <CreateOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {permissionCheckFnforCamo({
                        primaryKey: "occm_component",
                        keyIndex: "D",
                    }) ||
                        permissionCheckFnforCamo({
                            primaryKey: "occm_component",
                            keyIndex: "OD",
                            userId: item.created_by,
                        }) ? (
                        <Tooltip title="Delete" arrow>
                            <IconButton className="delete-icon" onClick={handleClick}>
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    <RevisionHistory buttonType={true} url={svurl} />
                    <Tooltip title="Reset TSLSV/CSLSV" arrow>
                       <span>
                            <Switch
                                className="sv-reset-tslsv-cslsv"
                                checked={svToggel}
                                onChange={() => resetTslvCslv(svToggel)}
                                color="primary"
                                id="sv_reset_tslsv_cslsv"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell> {item.removal_date ? formatDate(item.removal_date) : "--"} </TableCell>
                <TableCell style={{ width: "200px", textAlign: "left" }}>
                    {item.removal_reason && item.removal_reason.length > 150 ? (
                        <div>
                            {showFullDescription[item.id] ? (
                                <span>
                                    {item.removal_reason}
                                    <a
                                        href="#"
                                        style={{ color: "#0e7fe1" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleDescription(item.id);
                                        }}
                                    >
                                        ..less
                                    </a>
                                </span>
                            ) : (
                                <span>
                                    {item.removal_reason.substring(0, 150)}
                                    <a
                                        href="#"
                                        style={{ color: "#0e7fe1" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleDescription(item.id);
                                        }}
                                    >
                                        ..more
                                    </a>
                                </span>
                            )}
                        </div>
                    ) : item.removal_reason && item.removal_reason.length <= 150 ? (
                        <span>{item.removal_reason}</span>
                    ) : (
                        "--"
                    )}
                </TableCell>
                <TableCell>{item.tsn_at_removal ? regexConstants.alphanumespcespclchar.test(item.tsn_at_removal) ? item.tsn_at_removal : Comma_format(item.tsn_at_removal) : "--"}</TableCell>
                <TableCell>{item.csn_at_removal ? regexConstants.alphanumespcespclchar.test(item.csn_at_removal) ? item.csn_at_removal : Comma_format(item.csn_at_removal) : "--"}</TableCell>
                <TableCell> {item.sv_completion_date ? formatDate(item.sv_completion_date) : "--"} </TableCell>
                <TableCell>{item.sv_location_mro ? item.sv_location_mro : "--"}</TableCell>
                <TableCell> {item.sv_workscope ? item.sv_workscope : "--"} </TableCell>
                <TableCell> {item.sv_type ? item.sv_type : "--"} </TableCell>
                <TableCell>
                    {attachmentCount ? (
                        <>
                            <Tooltip title="View Attachments">
                                <span className="center" onClick={() => downAllAttach()} >
                                    <AttachFileIcon className="attach-icon" />
                                    <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                                </span>
                            </Tooltip>
                        </>
                    )
                        : (
                            "--"
                        )}
                </TableCell>
            </TableRow>
            <Dialog open={open} onClose={handleClose} id="htDialog">
                <ConfirmDialog
                    handleClose={() => handleClose()}
                    handleDeleteRow={handleDeleteRow}
                />
            </Dialog>
            <Dialog
                position="relative"
                maxWidth="lg"
                open={openEditForm}
            >
                <SvTaskForm
                    actionType={actionType}
                    formTitle={formTitle}
                    closeAddForm={() => closeAddForm()}
                    addForm={()=>  setEditForm(true)}
                    props={props}
                    editFormId={editFormId}
                    editFormData={editFormData}
                    attachments={attachments}
                    item={item}
                    updateFormData={updateFormData}
                    fileUploadData={fileUploadData}
                    currentRecordPerPage={currentRecordPerPage}
                    uuid={uuid}
                    last_used_folder_uuid={last_used_folder_uuid}
                    last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
                    checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
                    checkbox_last_used_folder_name={checkbox_last_used_folder_name}
                    getResponseBack={() => getResponseBack()}
                    basicDetail={basicDetail}
                    techSpecDetail={techSpecDetail}
                />
            </Dialog>
            <Dialog
                open={openGetAttachmentsDialogue}
                onClose={handleCloseAttachmentDialogue}
                id="htDialog"
            >
                <ShowAttachments
                    handleClose={() => handleCloseAttachmentDialogue()}
                    handleShowAttachments={AllAttachmentsforDialogue}
                    openAttachment={openAttachment}
                    files={{ title: "Shop Visit", extension: "zip", key: "" }}
                    downloadAllApi={downloadAllApi}
                    moduleId={moduleId}
                    item={item}
                    removeAttachment={removeAttachment}
                />
            </Dialog>
        </>
    );
};
export default withRouter(SvList);